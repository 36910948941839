import React, { Component } from 'react';

class Card extends Component {
    render() {
        const { id, name, image_url, desc } = this.props;
        return (
            <div onClick={() => {}} className='tc dib br3 ma2 bw2 shadow-5 grow2' style={{background: '#3b7ea1', color: 'white'}}>
                <img src={image_url} alt="project" />
                <div>
                    <h2 className='cardHead'>{name}</h2>
                    <p className='cardP'>{desc}</p>
                </div>
            </div>
        );
    }
}

export default Card;
