import React from 'react';
import './App.css';
import profile from './images/profile.jpeg'
import arxiv from './images/arxiv-logo.png'
import ezero from './images/ezero.png'
import emmet from './images/emmet.png'
import Card from './Card';
import resume from './Dev_s_Resume.pdf';
import victim from './images/victim_cache.png'
import branch from './images/branch prediction.png'
import fullstack from './images/fullstack.png'
import mnist from './images/mnist.png'

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};


const projects = [
  {
    id: 2,
    name: 'Branch Prediction',
    image_url: branch,
    desc: (
      <>
        Implemented 3 branch prediction strategies: local history with global history sharing (GShare), local history with global selection (GSelect), and bimodal with global history sharing (GShare). Our final implementation surpassed the MIPS R10000 branch predictor in performance on benchmarks. More details <a style={{ textDecoration: 'none', color: '#ffd200' }} href="https://docs.google.com/document/d/1l7xNSIKgJvEA0RI-7gicdRVz5b-4_YU5v2n8oYIhP9c/edit?usp=sharing">here</a>
      </>
    )
  },
  {
    id: 3,
    name: 'Cache Replacement and Victim Cache',
    image_url: victim,
    desc: (
      <>
        Implemented a fully associative L1 and victim cache hierarchy, testing LRU and FIFO eviction policies on SPEC CPU2006 benchmarks while varying cache associativity. Details available <a style={{ textDecoration: 'none', color: '#ffd200' }} href="https://docs.google.com/document/d/10j6yaDEnWFM-O3FyoT40Qccj6-3nRYCcGlTzbyilO5Y/edit?usp=sharing">here</a>
      </>
    )
  },
  {
    id: 4,
    name: 'Full-stack Website',
    image_url: fullstack,
    desc: (
      <>
        Built a full-stack website using React for front end, express for back end and postgresql for database. Hosted using render. The website uses the clarifai API to detect faces in images and features user login functionality. Website public <a style={{ textDecoration: 'none', color: '#ffd200' }} href="https://face-detection-frontend.onrender.com/">here</a>
      </>
    )
  },
  {
    id: 5,
    name: 'Convolution in Assembly',
    image_url: mnist,
    desc: (
      <>
        Developed a two-layer MNIST digit classifying network entirely in assembly, utilizing pre-trained weight matrices for
        accurate classification of flattened image vectors. Full implementation available on request. 
      </>
    )
  },
  {
    id: 1,
    name: 'SIXT33N',
    image_url: '',
    desc: 'Voice controlled car programmed using an arduino leonardo. Uses feedback to steer the car and PCA for classification of commands. Full implementation available on request.'
  },
  {
    id: 6,
    name: 'Zelda Coin Rush',
    image_url: '',
    desc: (
      <>
        Developed a tile-based Java game featuring pseudo-randomly generated maps and dynamic coin placement with a time-based coin collection challenge to achieve victory. Gameplay available <a style={{ textDecoration: 'none', color: '#ffd200' }} href="">here</a> and full implementation available on request.
      </>
    )
  },


]


function App() {
  return (
    <div>
      <header>
        <div id="head" href='#about' onClick={scrollToTop}>Dev Sajnani</div>
        <nav>
          <a className='links' onClick={() => scrollToSection('research')}>research</a>
          {/* <a className='links' onClick={() => scrollToSection('projects')}>projects</a> */}
          <a className='links' href={resume}>resume</a>
        </nav>
      </header>
      <div id='container' style={{ display: 'flex' }}>
        <div id='about'>
          <img id='profile' src={profile} alt="profile" />
          <ul className="contact-list">
            <li><a href="mailto:sajnanidev@berkeley.edu"> <i className="fas fa-envelope"></i> sajnanidev [at] berkeley [dot] edu</a></li>
            <li><a href="https://github.com/DevSajnani"><i className="fa fa-github"></i>
              <span>  </span>GitHub
            </a></li>
            <li><a href="https://www.linkedin.com/in/dev-sajnani"><i className="fa fa-linkedin"></i> LinkedIn</a></li>
          </ul>
        </div>

        <div id='desc'>
          <h1 id='name'>Dev Sajnani</h1>
          <span style={{ textAlign: 'left'}}>
            <p style={{width: '80%'}}>
              👋Hi, I'm a senior at UC Berkeley and my interests lie in ml and vlsi design. <br />
            <hr style={{opacity:'0'}}/>
            Beyond classes, I'm involved in efforts across Berkeley aimed at raising awareness about safe AI. <br />
            In the past I spent significant time thinking about the problem of interpretability in deep neural networks. 
            Though I've moved away from this, I hope to return to it some day. 
            <hr style={{opacity:'0'}}/>
            I'm also a proud member of the Mu chapter of HKN where I take photos and videos. </p>
          </span>
        </div>
      </div>

      <div id='research'>
        <h1>Research</h1>
        <div id='emmet' style={{ marginBottom: '2em' }}>
          <h2 style={{ marginBottom: '10px' }}>A Unified Framework for Model Editing</h2>
          <div style={{ display: 'flex', }}>
            <a href='https://github.com/scalable-model-editing/unified-model-editing' style={{ padding: '0', margin: '0' }}>
              <span style={{ padding: '0', margin: '0' }}><i className="fa fa-github"></i></span>
            </a>
            &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <a href='https://arxiv.org/abs/2403.14236' style={{ padding: '0', margin: '0' }}>
              <img style={{ overflow: 'hidden', height: '20px', }} src={arxiv} alt="" />
            </a>
          </div>
          <div className='researchKey'>
            <p className='researchText' >
              We present a new preservation-memorization objective for 'locate-then-edit' model editing methods, uniting two popular model editing algorithms - ROME and MEMIT - as equality-constrained and least-square constrained functions under the same objective.
              We then present EMMET, an equality-constrained batched editing algorithm that can perform up to 10,000 edits tested on
              GPT2-XL, GPT-J, and Llama-2.
            </p>
            <img className='researchImage' src={emmet} alt="" />
          </div>
        </div>

        <div id='ezero'>
          <h2 style={{ marginBottom: '10px' }} >Feature Hunting in RL Models</h2>
          <div style={{ display: 'flex', }}>
            <a href='https://github.com/DevSajnani/EZero' style={{ padding: '0', margin: '0' }}>
              <span style={{ padding: '0', margin: '0' }}><i className="fa fa-github"></i></span>
            </a>
            {/* &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; 
          <a href='https://arxiv.org/abs/2403.14236' style={{padding:'0', margin:'0'}}>
          <img style={{overflow: 'hidden', height:'20px',}} src={arxiv} alt="" />
          </a> */}
          </div>
          <div className='researchKey'>
            <p className='researchText'>
              We discover interpretable features in the EfficientZero RL model. First, we train sparse autoencoders to extract
              features, capturing the activations of neurons and reconstructing them. We then employ gradient-based attribution to
              identify elements within frames that
              correspond to specific features.
            </p>
            <img className='researchImage' src={ezero} alt="" />
          </div>
        </div>
      </div>

      {/* <div id='projects'>
        <h1>Projects</h1>
        <div>
          {
            projects.map((project, i) => {
              return (
                <div className='projectCard' key={i} >
                  <Card
                    id={project.id}
                    name={project.name}
                    image_url={project.image_url}
                    desc={project.desc}
                  />
                </div>

              )
            })

          }
        </div> */}
      {/* </div> */}

      <footer id='foot'>
        <div >
          website designed and built by dev sajnani. can you guess the color palette?
        </div>
      </footer>
    </div>

  )
}


export default App;
